<!--
File: ApiPath.vue
Description: show list of API paths entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('label.select_type')" v-model="selectedMethod" :items="getApiMethodsList()"
            :valueField="'id'" :displayField="'description'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="syncToDB"> {{ $t('buttons.sync') }} </md-button>
          <md-button class="md-success" @click="handleEdit()"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      <md-table :value="searchedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.id')" md-sort-by="api_path_id">
            {{ item.api_path_id }}
          </md-table-cell>
          <md-table-cell :md-label="$t('route.api_path')" md-sort-by="api_path">
            {{ item.api_path }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.method')" md-sort-by="method">
            {{ item.method }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'

  export default {
    name: 'api-path-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        selectedMethod: null,
        showSpinner: false,

        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['method', 'api_path'],
        currentSort: 'api_path',
        currentSortOrder: 'asc',
        fuseSearch: null
      }
    },

    components: {
      Pagination,
      BaseDropdown
    },

    async mounted() {
      this.toggleSpinner(true)
      await this.loadApiPathList(false)
      this.toggleSpinner(false)
    },

    methods: {
      ...mapActions({
        loadApiPathList: 'LOAD_API_PATH_LIST',
        getApiUrlMap: 'GET_API_URL_MAP',
        addApiPath: 'ADD_API_PATH',
        updApiPath: 'UPD_API_PATH',
        syncApiPath: 'SYNC_API_PATH',
        loadHistory: 'LOAD_HISTORY',
        clearHistory: 'CLEAR_HISTORY'
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      async handleEdit(item) {
        const isNew = !item;
        const { value } = await this.doubledInput(
          isNew ? this.$t('label.add_api_path') : this.$t('label.edit_api_path'),
          this.$t('label.select_method'),
          this.getApiMethodsList(false),
          this.$t('label.enter_path'),
          isNew ? '' : item.method,
          isNew ? '' : item.api_path
        ); 
        if (!value || value == []) { //User cancelled input
          return
        }
        this.toggleSpinner(true)
        /* TODO: return back when Andrey finishes API
        const entry = {
          "method": value[0],
          "path": value[1]
        }
        //console.log('entry', entry)
        if (isNew) {
          this.addApiPath(entry)
        } else {
          this.updApiPath({ api_path_id: item.api_path_id, api_path: entry })
        }*/
        this.toggleSpinner(false)
      },

      syncToDB() {
        this.syncApiPath().then(res => {
          console.log(res)
          this.successMessage('', this.$t('messages.synchronized'))
        })
      },

      handleDelete(item) {
        //this.deletedMessage(`${this.$t('label.component_type')}: ${item.component_type}, name:${item.name}`)
      }
    },

    computed: {
      ...mapState({
        //apiPathList: (state) => state.RBAC.apiPathList,
      }),

      ...mapGetters(['amIAdmin', 'getApiMethodsList', 'getApiPathByMethod']),

      apiPathList() {
        const res = this.getApiPathByMethod(this.selectedMethod)
        this.fuseSearch = new Fuse(res, { keys: this.propsToSearch, threshold: 0.1 });
        return res
      },

      apiPathCount() {
        return this.apiPathList.length
      },

      getMethods: () => (addEmptyLine = true) => {
        return this.getApiMethodsList(addEmptyLine)
      },

      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },

      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },

      total() {
        return this.searchQuery ? this.searchedData.length : this.apiPathCount
      },

      searchedData() {
        if (!this.apiPathCount) {
          return []
        }
        return (!this.searchQuery) ? this.apiPathList : this.fuseSearch.search(this.searchQuery)
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>